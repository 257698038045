<template>
  <div class="groupBuyingList">
    <div class="flexbox flex-lr flex-align-center">
      <el-input v-model="searhApplyRiderForm.agentOrApplyName" placeholder="代理人/申请人姓名" style="width:200px;" clearable></el-input>
      <el-input v-model="searhApplyRiderForm.tradingAreaName" placeholder="学校名称" style="width:200px;margin-left:20px;" clearable></el-input>
      <el-input v-model="searhApplyRiderForm.applyerPhone" placeholder="手机号" type="number" style="width:200px;margin-left:20px;"
        class="mr20" clearable>
      </el-input>
      <el-date-picker class="mr20" :clearable="false" v-model="searhApplyRiderForm.applyTime" type="daterange" range-separator="至"
        value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-button type="primary" @click="search">筛选</el-button>
      <el-button type="info" @click="clear">清空</el-button>
    </div>
    <div class="tableBox">
      <el-table v-loading="loading" :data="applyRiderTable" style="width: 100%" border>
        <el-table-column prop="createTime" label="申请时间" align="center"></el-table-column>
        <el-table-column prop="agentName" label="归属代理商" align="center"></el-table-column>
        <el-table-column prop="tradingAreaName" label="归属学校" align="center"></el-table-column>
        <el-table-column prop="applyName" label="真实姓名" align="center"></el-table-column>
        <el-table-column prop="applyerPhone" label="手机号码" align="center"></el-table-column>
        <el-table-column prop="riderApplyExtInfo.professionalClass" label="专业班级" align="center">
        </el-table-column>
        <el-table-column prop="riderApplyExtInfo.grade" label="年级" align="center">
        </el-table-column>
        <el-table-column prop="riderApplyExtInfo.dormitoryArea" label="宿舍区域" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.riderApplyExtInfo? 
              scope.row.riderApplyExtInfo.dormitoryArea+scope.row.riderApplyExtInfo.dormitoryNumber:""}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="riderApplyExtInfo.dormitoryArea" label="宿舍号码" align="center"> -->
        <!-- </el-table-column> -->
        <el-table-column prop="applyerGender" label="性别" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.applyerGender===1">男</span>
            <span v-if="scope.row.applyerGender===2">女</span>
          </template>
        </el-table-column>
        <el-table-column prop="auditState" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.auditState===0">待处理</span>
            <span v-if="scope.row.auditState===1">已处理</span>
            <span v-if="scope.row.auditState===2">已处理</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.auditState===0" @click="openApplyDialog(scope.row.riderApplyId,1)">去处理</el-button>
            <el-button type="text" v-if="scope.row.auditState!==0" @click="openApplyDialog(scope.row.riderApplyId,2)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <el-dialog width="600px" :visible='showApplyRiderDialog' center @close="showApplyRiderDialog=false">
      <span slot='title' style="font-size:18px;font-weight:bold;">
        申请人详情
      </span>
      <p class="mt10 mb10"><span class="distri-layout-mark"></span><span class="distri-layout-title">基础信息</span></p>
      <div class="basicInfo">
        <div class="leftContent">
          <p>申请时间：{{riderInfoForm.createTime||'-'}}</p>
          <p>归属代理商：{{riderInfoForm.agentName||'-'}}</p>
          <p>代理商联系电话：{{riderInfoForm.agentPhone||'-'}}</p>
          <p>归属学校：{{riderInfoForm.tradingAreaName||'-'}}</p>
          <p>宿舍区域：{{riderInfoForm.riderApplyExtInfo? 
            riderInfoForm.riderApplyExtInfo.dormitoryArea+riderInfoForm.riderApplyExtInfo.dormitoryNumber||'-'  :'-'}}</p>
        </div>
        <div class="rightContent">
          <p>真实姓名：{{riderInfoForm.applyName ||'-'}}</p>
          <p>性别：{{riderInfoForm.applyerGender==1? '男':'女'}}</p>
          <p>手机号：{{riderInfoForm.applyerPhone ||'-'}}</p>
          <p>专业班级：{{riderInfoForm.riderApplyExtInfo? riderInfoForm.riderApplyExtInfo.professionalClass||'-':'-'}}</p>
          <p>年级：{{riderInfoForm.riderApplyExtInfo? riderInfoForm.riderApplyExtInfo.grade||'-':'-'}}</p>
        </div>
      </div>
      <p class="mt10 mb10"><span class="distri-layout-mark"></span><span class="distri-layout-title">其他信息</span></p>
      <div class="otherInfo">

        <p><span>有晚自习（是否每天都有在上晚自习）</span>
          <span v-if="riderInfoForm.riderApplyExtInfo.isLateSelfStudy"><i class="el-icon-circle-check"
              style="font-size: 25px; color:red;"></i></span>
          <span v-else><i class="el-icon-circle-close" style="font-size: 25px;"></i></span>
        </p>

        <p><span>长期兼职（大于两个月为长期）</span>
          <span v-if="riderInfoForm.riderApplyExtInfo.isLongTermPartTime"><i class="el-icon-circle-check"
              style="font-size: 25px; color:red;"></i></span>
          <span v-else><i class="el-icon-circle-close" style="font-size: 25px;"></i></span>
        </p>

        <p><span>周末在校（周末是否能在校）</span>
          <span v-if="riderInfoForm.riderApplyExtInfo.isSchoolOnWeekends"><i class="el-icon-circle-check"
              style="font-size: 25px; color:red;"></i></span>
          <span v-else><i class="el-icon-circle-close" style="font-size: 25px;"></i></span>
        </p>

      </div>
      <p class="mt10 mb10"><span class="distri-layout-mark"></span><span class="distri-layout-title">备注处理情况</span></p>
      <div class="remarks">
        <span>备注：</span>
        <el-input :disabled="type===2? true:false" type="textarea" maxlength="200" show-word-limit :rows="2" placeholder="请输入内容"
          v-model="editApplyRiderForm.auditDesc">
        </el-input>
      </div>
      <span v-if="type===1" slot="footer" class="dialog-footer">
        <el-button @click="showApplyRiderDialog = false">取 消</el-button>
        <el-button type="primary" @click="applyRiderSubmit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { $sg_getSchoolRiderAplyList, $sg_getSchoolRiderDetail, $sp_editSchoolRiderDetail } from '@/api/schoolRiderApply';

export default {
  data() {
    return {
      type: '', // 1处理 2 详情
      loading: false,
      showApplyRiderDialog: false,
      totalPage: 1,
      currentPage: 1,
      editApplyRiderForm: { // 修改骑手申请信息
        auditDesc: '',
        riderApplyId: '',
      },
      searhApplyRiderForm: {
        applyTime: ['', ''],
        agentOrApplyName: '',
        tradingAreaName: '',
        applyerPhone: '',
      },
      applyRiderTable: [
      ],
      riderInfoForm: {
        riderApplyExtInfo: {},
      },
    };
  },
  created() {
    this.getApplyRiderData();
  },
  methods: {
    // 判断骑手是否选择长期、晚自习
    checkRider(number) {
      if (number) {
        return true;
      } else {
        return false;
      }
    },
    // 打开骑手申请弹窗
    openApplyDialog(id, type) {
      this.type = type;
      this.editApplyRiderForm.riderApplyId = id;
      $sg_getSchoolRiderDetail({ riderApplyId: id }).then((res) => {
        this.riderInfoForm = res;
        this.editApplyRiderForm.auditDesc = this.riderInfoForm.auditDesc;
        console.log(this.riderInfoForm, '12313213');
        this.showApplyRiderDialog = true;
      }).catch(() => {
      });

      // this.riderInfoForm = row;
      console.log(type);
    },
    // 提交骑手申请
    applyRiderSubmit() {
      const params = JSON.parse(JSON.stringify(this.editApplyRiderForm));
      $sp_editSchoolRiderDetail(
        params,
      ).then(() => {
        this.showApplyRiderDialog = false;
      }).catch(() => {
        this.$message.error('保存失败');
      });
      this.getApplyRiderData();
    },
    // 筛选
    search() {
      const params = this.searhApplyRiderForm;
      this.currentPage = 1;
      this.getApplyRiderData({
        ...params,
        beginTime: params.applyTime[0],
        endTime: params.applyTime[1],
      });
    },
    // 清空
    clear() {
      this.searhApplyRiderForm = {
        applyTime: ['', ''],
        agentOrApplyName: '',
        tradingAreaName: '',
        applyerPhone: '',
      };
      this.currentPage = 1;
      this.getApplyRiderData();
    },
    // 请求列表数据
    getApplyRiderData(params) {
      this.loading = true;
      $sg_getSchoolRiderAplyList({
        pageCurrent: this.currentPage,
        pageSize: 15,
        ...params,
      }).then((res) => {
        console.log(res);
        const records = res.records;
        this.applyRiderTable = records;
        console.log(this.applyRiderTable, '121312');
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    // 翻页
    pageChange() {
      this.getApplyRiderData(this.searhApplyRiderForm);
    },
  },
};
</script>
<style lang="scss" scoped>
.groupBuyingList {
  width: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tips {
    width: 100%;
    margin-top: 40px;
    font-size: 14px;
    color: #666;
  }
  .tableBox {
    width: 100%;
    margin-top: 10px;
  }
  .el-table {
    min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .distri-layout-mark {
    width: 3px;
    display: inline-block;
    height: 19px;
    background: #ef3f46;
    opacity: 1;
  }
  .distri-layout-title {
    padding: 0 10px;
    vertical-align: 4px;
    font-size: 16px;
    color: #4d4d4d;
  }
  .basicInfo {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    p {
      margin-bottom: 5px;
    }
  }
  .otherInfo {
    padding-left: 10px;
    padding-right: 10px;
    > p {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
    }
    .el-checkbox {
      /deep/.el-checkbox__inner {
        border-radius: 50%;
      }
    }
  }
  .remarks {
    > span {
      width: 50px;
    }
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>

